import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = {
  type: "button",
  class: "btn btn-sm btn-flex btn-light-primary me-2",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_new_packages"
}
const _hoisted_9 = { class: "svg-icon svg-icon-3" }
const _hoisted_10 = {
  id: "kt_shipment_create_packages",
  class: "collapse show"
}
const _hoisted_11 = { class: "card-body border-top p-9" }
const _hoisted_12 = {
  key: 0,
  class: "notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6"
}
const _hoisted_13 = { class: "svg-icon svg-icon-2tx svg-icon-primary me-4" }
const _hoisted_14 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_15 = { class: "fw-bold" }
const _hoisted_16 = { class: "fs-6 text-gray-600" }
const _hoisted_17 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_18 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_19 = { class: "badge-light-info badge" }
const _hoisted_20 = { class: "badge-light-info badge" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "svg-icon svg-icon-3" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "svg-icon svg-icon-3" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "svg-icon svg-icon-3" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'merchant-packages') || _ctx.can('read', 'supplier-packages'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('shipmentPackages')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-flex btn-light-primary me-2",
              "data-bs-toggle": "modal",
              disabled: !_ctx.createdShipment.isEditable,
              "data-bs-target": "#kt_modal_import_packages"
            }, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr078.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('importPackages')), 1)
            ], 8, _hoisted_6),
            _withDirectives(_createElementVNode("button", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen035.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewPackages')), 1)
            ], 512), [
              [_vShow, _ctx.createdShipment.isEditable]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (!_ctx.createdShipment)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen045.svg" })
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate('savingShipmentPackagesNote')), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Datatable, {
              "table-header": _ctx.tableHeader,
              "table-data": _ctx.tableData,
              "rows-per-page": 50,
              total: _ctx.totalItems,
              onCurrentChange: _ctx.pageChanged,
              loading: _ctx.loading,
              "enable-items-per-page-dropdown": false
            }, {
              "cell-isPrinted": _withCtx(({ row: packages }) => [
                (packages.isPrinted)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.translate('Yes')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.translate('No')), 1))
              ]),
              "cell-trackNumber": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.trackNumber), 1)
              ]),
              "cell-holderName": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.holderName), 1)
              ]),
              "cell-areaName": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.areaName), 1)
              ]),
              "cell-recipientPhoneNumber": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.recipientPhoneNumber), 1)
              ]),
              "cell-docId": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.docId), 1)
              ]),
              "cell-address": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.address), 1)
              ]),
              "cell-internalStatus": _withCtx(({ row: packages }) => [
                _createElementVNode("span", _hoisted_19, _toDisplayString(packages.internalStatus), 1)
              ]),
              "cell-externalStatus": _withCtx(({ row: packages }) => [
                _createElementVNode("span", _hoisted_20, _toDisplayString(packages.externalStatus), 1)
              ]),
              "cell-actions": _withCtx(({ row: packages }) => [
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2",
                  onClick: _withModifiers(($event: any) => (
                _ctx.createdShipment.shipmentType === 'supplier'
                  ? _ctx.supplierOverView(packages.id)
                  : _ctx.merchantOverView(packages.id)
              ), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_22, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
                  ])
                ], 8, _hoisted_21),
                (
                _ctx.can('update', 'merchant-packages') ||
                _ctx.can('update', 'supplier-packages')
              )
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#kt_modal_edit_packages",
                      href: "#",
                      class: _normalizeClass([_ctx.disClass(_ctx.createdShipment.isEditable), "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"]),
                      onClick: _withModifiers(($event: any) => (_ctx.editOption(packages.id)), ["prevent"])
                    }, [
                      _createElementVNode("span", _hoisted_24, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                      ])
                    ], 10, _hoisted_23))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2",
                  onClick: _withModifiers(($event: any) => (_ctx.printBtn(packages)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_26, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/files/fil025.svg" })
                  ])
                ], 8, _hoisted_25),
                _createElementVNode("a", {
                  href: "#",
                  class: _normalizeClass([_ctx.disClass(_ctx.createdShipment.isEditable), "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"]),
                  onClick: _withModifiers(($event: any) => (_ctx.toggleStatusPackages(packages.id)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_28, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                  ])
                ], 10, _hoisted_27)
              ]),
              _: 1
            }, 8, ["table-header", "table-data", "total", "onCurrentChange", "loading"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}