
  import { computed, defineComponent, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'packages-creating',
    props: {
      shipmentId: { type: String, required: true },
      statusId: { type: String, required: true },
    },
    emit: ['list-changed'],
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newPackagesModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const selectedArea = ref<string>('');
      const selectedGovernorate = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => selectedGovernorate.value,
        async () => {
          if (selectedGovernorate.value) {
            selectedArea.value = '';
            await store.dispatch(
              Actions.GET_SHIPMENT_AREAS,
              selectedGovernorate.value
            );
          }
        }
      );
      const validationSchema = Yup.object().shape({
        holderName: Yup.string()
          .required(() => translate('HOLDER_NAME_IS_REQUIRED_FIELD'))
          .label('Holder Namr'),
        docId: Yup.string()
          .required(() => translate('DOC_ID_IS_REQUIRED_FIELD'))
          .label('docId'),
        recipientPhoneNumber: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('RECIPIENT_PHONE_NUMBER_IS_REQUIRED_FIELD'))
          .label('Mobile'),
        secondRecipientPhoneNumber: Yup.string()
          .nullable()
          .test(
            'is-valid-mobile',
            'Mobile number is not valid',
            function (value) {
              if (value === null) return true;
              return !value || /^(77|78|75)\d{8}$/.test(value);
            }
          ),
        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('address'),
      });

      const submit = async (values) => {
        if (!submitButtonRef.value) {
          return;
        }

        //Disable button
        submitButtonRef.value.disabled = true;
        // Activate indicator
        submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        values.shipmentId = props.shipmentId;
        values.statusId = props.statusId;
        values.packageType = 'supplier';
        values.areaId = selectedArea.value;
        delete values.governorateid;
        values.recipientPhoneNumber = String(
          '964' + values.recipientPhoneNumber
        );
        if (values.secondRecipientPhoneNumber !== undefined) {
          values.secondRecipientPhoneNumber = String(
            '964' + values.secondRecipientPhoneNumber
          );
        }
        const data = await store.dispatch(Actions.CREATE_SHIPMENT_PACKAGE, {
          data: values,
        });

        if (data?.id) {
          // await store.dispatch(Actions.UPLOAD_SHIPMENT_ATTACHMENTS, {
          //   data: attachmentsFormData,
          //   auctionId: data.id,
          // });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PACKAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('list-changed');
            hideModal(newPackagesModalRef.value);
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
          });
        }
      };

      const areas = computed(() => store.getters.getAllAreasList);
      const areasTitle = () => {
        return areas.value.map((i) => {
          return { title: i.titleTranslate, id: i.id };
        });
      };

      const governorate = computed(() => store.getters.allGovernoratesList);
      const governorateTitle = () => {
        return governorate.value.map((i) => {
          return {
            title: i.title.find((r) => r.languageCode === 'en')?.title,
            id: i.id,
          };
        });
      };

      // await Promise.all([await store.dispatch(Actions.GET_HUB_AREAS)]);
      return {
        governorates: computed(() => store.getters.allGovernoratesList),
        translate,
        governorate,
        validationSchema,
        submit,
        selectedGovernorate,
        selectedArea,
        submitButtonRef,
        newPackagesModalRef,
        areasTitle,
        governorateTitle,
        areas,
      };
    },
  });
