
  import { computed, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import printJs from 'print-js';
  import { useRouter } from 'vue-router';
  import jsPDF from 'jspdf';

  export default defineComponent({
    name: 'packages-listing',
    emit: ['list-changed', 'show-edit-packages-modal'],
    props: {
      createdShipment: { type: Object, default: null },
    },
    components: {
      Datatable,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      const disClass = (isEditable) => {
        if (!isEditable)
          return 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm disabled';
        else return 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm';
      };

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('Prented'),
          key: 'isPrinted',
          sortable: false,
        },
        {
          name: translate('Package ID'),
          key: 'trackNumber',
          sortable: false,
        },
        {
          name: translate('holderName'),
          key: 'holderName',
          sortable: false,
        },
        {
          name: translate('areaName'),
          key: 'areaName',
          sortable: false,
        },
        {
          name: translate('recipientPhoneNumber'),
          key: 'recipientPhoneNumber',
          sortable: false,
        },
        {
          name: translate('docId'),
          key: 'docId',
          sortable: false,
        },
        {
          name: translate('address'),
          key: 'address',
          sortable: false,
        },
        {
          name: translate('internalStatus'),
          key: 'internalStatus',
          sortable: true,
        },
        {
          name: translate('externalStatus'),
          key: 'externalStatus',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);
      const tableData = computed(() => store.getters.shipmentPackagesList);
      const totalItems = computed(
        () => store.getters.ShipmentPackagesListCount
      );

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_SHIPMENTS_PROPERTY_CURRENT_PAGE,
          val
        );
        await store.dispatch(
          Actions.GET_SHIPMENT_PROPERTIES,
          props.createdShipment.id
        );
        loading.value = false;
      };

      const toggleStatusPackages = async (packagesId) => {
        const deletedMessage = 'confirmInActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_SHIPMENT_PROPERTY, packagesId);
          emit('list-changed');
        }
      };
      const editOption = (id) => {
        emit('show-edit-packages-modal', id);
      };

      const printBtn = async (generateData) => {
        var doc = new jsPDF();

        doc.setFontSize(12);

        doc.addImage(generateData['qrCodeImage'], 'png', 15, 75, 75, 75);
        doc.text('Holder Name: ' + generateData.holderName, 20, 25);
        doc.text('Track Number : ' + generateData.trackNumber, 20, 35);
        doc.text('Document ID : ' + generateData.docId, 20, 45);
        doc.text('Phone : ' + generateData.recipientPhoneNumber, 20, 55);
        doc.text('address : ' + generateData.address, 20, 65);
        doc.text('notes : ' + generateData.notes, 20, 75);

        doc.autoPrint();

        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);

        printJs({ printable: blobUrl, type: 'pdf' });

        await store
          .dispatch(Actions.UPDATE_SHIPMENTS_PACKAGE, {
            data: { isPrinted: true },
            shipmentId: generateData.id,
          })
          .then(() => {
            emit('list-changed');
          });
      };

      const merchantOverView = (id) => {
        router.push({ name: 'merchant-packages-overview', params: { id } });
      };

      const supplierOverView = (id) => {
        router.push({ name: 'supplier-packages-overview', params: { id } });
      };

      return {
        merchantOverView,
        supplierOverView,
        loading,
        pageChanged,
        totalItems,
        disClass,
        tableHeader,
        tableData,
        translate,
        can,
        editOption,
        toggleStatusPackages,
        printBtn,
      };
    },
  });
